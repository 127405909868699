// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* BLACK */
body.dark .sidebar__logo[data-v-50381602] {
  fill: #FFFFFF;
}
body.dark .compact.sidebar[data-v-50381602] {
  background: #1c1d2e;
}
.input[data-v-50381602] {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}
.input.noIcon input[data-v-50381602] {
    padding-left: 12px;
}
.input label[data-v-50381602] {
    font-size: 14px;
    line-height: 17px;
    color: #AFBCCB;
    position: absolute;
    top: 12px;
    left: 15px;
    z-index: 1;
    font-family: 'Panton_SemiBold';
}
@media (max-width: 1024.98px) {
.input label[data-v-50381602] {
        font-size: 12px;
        top: 8px;
}
}
.input label.warn[data-v-50381602] {
      color: #ca4508;
}
.input__label-info[data-v-50381602] {
    position: absolute;
    margin-left: 9px;
}
.input__label-info[data-v-50381602]:hover {
      fill: #0A2778;
}
.input input[data-v-50381602] {
    width: 100%;
    height: 100%;
    border: 1px solid #C3CEEB;
    border-radius: 8px;
    padding: 28px 50px 10px 36px;
    font-size: 14px;
    line-height: 17px;
    font-family: "Panton_SemiBold";
    text-indent: 2px;
}
@media (max-width: 1024.98px) {
.input input#walletSearch[data-v-50381602] {
        padding: 25px 25px 10px 36px;
}
}
.input input.input__currency[data-v-50381602] {
      padding-right: 15px;
}
.input input[type='number'][data-v-50381602]::-webkit-outer-spin-button, .input input[type='number'][data-v-50381602]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
}
.input input[type='number'][data-v-50381602] {
      -moz-appearance: textfield;
}
.input input[data-v-50381602]:focus {
      border: 1px solid #1A53F0;
}
.input input[data-v-50381602]:disabled {
      background: #F0F3FD;
      color: #6B93C0;
}
.input--type--currency input[data-v-50381602] {
    padding-right: 90px;
}
.input__icon[data-v-50381602] {
    max-width: 14px;
    max-height: 16px;
    left: 17px;
    bottom: 20px;
    position: absolute;
}
.input__icon--disabled[data-v-50381602] {
      fill: #6B93C0 !important;
}
@media (max-width: 1024.98px) {
.input__icon[data-icon='sidebar__search-field__icon'][data-v-50381602] {
        bottom: 13px;
}
}
.input__visibility-icon[data-v-50381602], .input__copy[data-v-50381602] {
    right: 17px;
    bottom: 14px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
}
.input__visibility-icon[data-v-50381602]:hover, .input__copy[data-v-50381602]:hover {
      fill: #1A53F0;
}
.input__clear-icon[data-v-50381602] {
    position: absolute;
    bottom: 0;
    margin: auto 0;
    top: calc(100% - 54px);
    right: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.input__clear-icon svg[data-v-50381602] {
      cursor: pointer;
      bottom: 3px !important;
      fill: #6B93C0;
}
.input__clear-icon svg[data-v-50381602]:hover {
        fill: #FA3B33;
}
.input__copy[data-v-50381602] {
    width: 14px;
    height: 15px;
}
.input__text[data-v-50381602] {
    position: absolute;
    right: 17px;
    bottom: 14px;
    font-size: 14px;
    line-height: 30px;
    font-family: 'Panton_Bold';
    text-transform: uppercase;
}
.input__error[data-v-50381602] {
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    bottom: -32px;
    font-size: 14px;
    font-weight: 600;
    line-height: 30px;
    color: #FA3B33;
}
.input__error svg[data-v-50381602] {
      position: relative;
      margin-right: 8px;
}
.input__tooltip[data-v-50381602] {
    background-color: #F0F3FD;
    text-align: center;
    border-radius: 6px;
    padding: 8px 9px;
    position: absolute;
    white-space: nowrap;
    z-index: 1;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 7px);
    box-shadow: 0 4px 25px rgba(63, 54, 137, 0.25);
    font-size: 12px;
    line-height: 16px;
    color: #0A2778;
}
.input__tooltip[data-v-50381602]::after {
      content: '';
      position: absolute;
      bottom: 98%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-radius: 2px;
      border-style: solid;
      border-color: transparent transparent #F0F3FD transparent;
}
.input__max[data-v-50381602] {
    right: 16px;
    bottom: 9px;
    position: absolute;
    cursor: pointer;
    font-size: 14px;
    line-height: 30px;
    font-family: 'Panton_Bold';
}
.input__currency[data-v-50381602] {
    position: absolute;
    bottom: 16px;
    padding-left: 5px;
    font-family: 'Panton_SemiBold';
    font-size: 14px;
    line-height: 17px;
    color: #6A4BFF;
    transition: left 0.1ms;
}
.input__hidden-value[data-v-50381602] {
    position: absolute;
    opacity: 0;
    z-index: -100;
    font-size: 14px;
    font-family: 'Panton_SemiBold';
    text-indent: 2px;
}
.error input[data-v-50381602] {
  border: 1px solid #FA3B33 !important;
}
.error input[data-v-50381602]:focus {
    border: 1px solid #1A53F0;
}
.empty input[data-v-50381602] {
  border-radius: 0;
  border: none;
  padding: 2px 20px 8px 31px;
  border-bottom: 1px solid #C3CEEB;
}
.empty input[data-v-50381602]:focus {
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #1A53F0;
}
.empty .input__icon[data-v-50381602] {
  left: 0;
  bottom: 6px;
  max-width: 20px;
  max-height: 20px;
  fill: #0A2778;
}
@media (max-width: 1279.96px) {
.empty .input__icon[data-v-50381602] {
      max-width: 16px;
      max-height: 16px;
      cursor: pointer;
}
}
.empty .input__clear-icon[data-v-50381602] {
  right: 0;
  bottom: 11px;
}
body.dark[data-v-50381602] {
  /* Change the white to any color */
}
body.dark .input input[data-v-50381602] {
    background: none !important;
    border: 1px solid #4b4c63;
    color: #FFFFFF;
}
body.dark .input__max[data-v-50381602] {
    color: #FFFFFF;
}
body.dark input[data-v-50381602]:-webkit-autofill,
  body.dark input[data-v-50381602]:-webkit-autofill:hover,
  body.dark input[data-v-50381602]:-webkit-autofill:focus,
  body.dark input[data-v-50381602]:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 50px #26273B inset !important;
}
body.dark input[data-v-50381602]:-webkit-autofill {
    -webkit-text-fill-color: #fff !important;
}
body.dark input[data-v-50381602]:-webkit-autofill {
    caret-color: white;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
