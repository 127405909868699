// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/Panton-BoldItalic.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../fonts/Panton-BoldItalic.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../fonts/Panton-BoldItalic.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../fonts/Panton-SemiBold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../fonts/Panton-SemiBold.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("../fonts/Panton-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("../fonts/Panton-ExtraBoldItalic.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("../fonts/Panton-ExtraBoldItalic.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_8___ = new URL("../fonts/Panton-ExtraBoldItalic.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_9___ = new URL("../fonts/Panton-ExtraLightItalic.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_10___ = new URL("../fonts/Panton-ExtraLightItalic.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_11___ = new URL("../fonts/Panton-ExtraLightItalic.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_12___ = new URL("../fonts/Panton-SemiBoldItalic.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_13___ = new URL("../fonts/Panton-SemiBoldItalic.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_14___ = new URL("../fonts/Panton-SemiBoldItalic.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_15___ = new URL("../fonts/Panton-Black.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_16___ = new URL("../fonts/Panton-Black.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_17___ = new URL("../fonts/Panton-Black.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_18___ = new URL("../fonts/Panton-BlackItalic.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_19___ = new URL("../fonts/Panton-BlackItalic.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_20___ = new URL("../fonts/Panton-BlackItalic.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_21___ = new URL("../fonts/Panton-Light.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_22___ = new URL("../fonts/Panton-Light.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_23___ = new URL("../fonts/Panton-Light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_24___ = new URL("../fonts/Panton-ExtraBold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_25___ = new URL("../fonts/Panton-ExtraBold.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_26___ = new URL("../fonts/Panton-ExtraBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_27___ = new URL("../fonts/Panton-Bold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_28___ = new URL("../fonts/Panton-Bold.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_29___ = new URL("../fonts/Panton-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_30___ = new URL("../fonts/Panton-Regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_31___ = new URL("../fonts/Panton-Regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_32___ = new URL("../fonts/Panton-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_33___ = new URL("../fonts/Panton-Thin.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_34___ = new URL("../fonts/Panton-Thin.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_35___ = new URL("../fonts/Panton-Thin.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_36___ = new URL("../fonts/Panton-HeavyItalic.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_37___ = new URL("../fonts/Panton-HeavyItalic.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_38___ = new URL("../fonts/Panton-HeavyItalic.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_39___ = new URL("../fonts/Panton-LightItalic.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_40___ = new URL("../fonts/Panton-LightItalic.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_41___ = new URL("../fonts/Panton-LightItalic.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_42___ = new URL("../fonts/Panton-ExtraLight.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_43___ = new URL("../fonts/Panton-ExtraLight.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_44___ = new URL("../fonts/Panton-ExtraLight.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_45___ = new URL("../fonts/Panton-ThinItalic.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_46___ = new URL("../fonts/Panton-ThinItalic.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_47___ = new URL("../fonts/Panton-ThinItalic.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_48___ = new URL("../fonts/Panton-RegularItalic.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_49___ = new URL("../fonts/Panton-RegularItalic.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_50___ = new URL("../fonts/Panton-RegularItalic.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_51___ = new URL("../fonts/Panton-Heavy.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_52___ = new URL("../fonts/Panton-Heavy.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_53___ = new URL("../fonts/Panton-Heavy.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
var ___CSS_LOADER_URL_REPLACEMENT_10___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_10___);
var ___CSS_LOADER_URL_REPLACEMENT_11___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_11___);
var ___CSS_LOADER_URL_REPLACEMENT_12___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_12___);
var ___CSS_LOADER_URL_REPLACEMENT_13___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_13___);
var ___CSS_LOADER_URL_REPLACEMENT_14___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_14___);
var ___CSS_LOADER_URL_REPLACEMENT_15___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_15___);
var ___CSS_LOADER_URL_REPLACEMENT_16___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_16___);
var ___CSS_LOADER_URL_REPLACEMENT_17___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_17___);
var ___CSS_LOADER_URL_REPLACEMENT_18___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_18___);
var ___CSS_LOADER_URL_REPLACEMENT_19___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_19___);
var ___CSS_LOADER_URL_REPLACEMENT_20___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_20___);
var ___CSS_LOADER_URL_REPLACEMENT_21___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_21___);
var ___CSS_LOADER_URL_REPLACEMENT_22___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_22___);
var ___CSS_LOADER_URL_REPLACEMENT_23___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_23___);
var ___CSS_LOADER_URL_REPLACEMENT_24___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_24___);
var ___CSS_LOADER_URL_REPLACEMENT_25___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_25___);
var ___CSS_LOADER_URL_REPLACEMENT_26___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_26___);
var ___CSS_LOADER_URL_REPLACEMENT_27___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_27___);
var ___CSS_LOADER_URL_REPLACEMENT_28___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_28___);
var ___CSS_LOADER_URL_REPLACEMENT_29___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_29___);
var ___CSS_LOADER_URL_REPLACEMENT_30___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_30___);
var ___CSS_LOADER_URL_REPLACEMENT_31___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_31___);
var ___CSS_LOADER_URL_REPLACEMENT_32___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_32___);
var ___CSS_LOADER_URL_REPLACEMENT_33___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_33___);
var ___CSS_LOADER_URL_REPLACEMENT_34___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_34___);
var ___CSS_LOADER_URL_REPLACEMENT_35___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_35___);
var ___CSS_LOADER_URL_REPLACEMENT_36___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_36___);
var ___CSS_LOADER_URL_REPLACEMENT_37___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_37___);
var ___CSS_LOADER_URL_REPLACEMENT_38___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_38___);
var ___CSS_LOADER_URL_REPLACEMENT_39___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_39___);
var ___CSS_LOADER_URL_REPLACEMENT_40___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_40___);
var ___CSS_LOADER_URL_REPLACEMENT_41___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_41___);
var ___CSS_LOADER_URL_REPLACEMENT_42___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_42___);
var ___CSS_LOADER_URL_REPLACEMENT_43___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_43___);
var ___CSS_LOADER_URL_REPLACEMENT_44___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_44___);
var ___CSS_LOADER_URL_REPLACEMENT_45___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_45___);
var ___CSS_LOADER_URL_REPLACEMENT_46___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_46___);
var ___CSS_LOADER_URL_REPLACEMENT_47___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_47___);
var ___CSS_LOADER_URL_REPLACEMENT_48___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_48___);
var ___CSS_LOADER_URL_REPLACEMENT_49___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_49___);
var ___CSS_LOADER_URL_REPLACEMENT_50___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_50___);
var ___CSS_LOADER_URL_REPLACEMENT_51___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_51___);
var ___CSS_LOADER_URL_REPLACEMENT_52___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_52___);
var ___CSS_LOADER_URL_REPLACEMENT_53___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_53___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* BLACK */
body.dark .sidebar__logo {
  fill: #FFFFFF; }

body.dark .compact.sidebar {
  background: #1c1d2e; }

@font-face {
  font-family: 'Panton_BoldItalic';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff2"), url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff"), url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("truetype");
  font-weight: bold;
  font-style: italic; }

@font-face {
  font-family: 'Panton_SemiBold';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("woff2"), url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format("woff"), url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format("truetype");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Panton_ExtraBoldItalic';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format("woff2"), url(${___CSS_LOADER_URL_REPLACEMENT_7___}) format("woff"), url(${___CSS_LOADER_URL_REPLACEMENT_8___}) format("truetype");
  font-weight: 800;
  font-style: italic; }

@font-face {
  font-family: 'Panton_ExtraLightItalic';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_9___}) format("woff2"), url(${___CSS_LOADER_URL_REPLACEMENT_10___}) format("woff"), url(${___CSS_LOADER_URL_REPLACEMENT_11___}) format("truetype");
  font-weight: 200;
  font-style: italic; }

@font-face {
  font-family: 'Panton_SemiBoldItalic';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_12___}) format("woff2"), url(${___CSS_LOADER_URL_REPLACEMENT_13___}) format("woff"), url(${___CSS_LOADER_URL_REPLACEMENT_14___}) format("truetype");
  font-weight: 600;
  font-style: italic; }

@font-face {
  font-family: 'Panton_Black';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_15___}) format("woff2"), url(${___CSS_LOADER_URL_REPLACEMENT_16___}) format("woff"), url(${___CSS_LOADER_URL_REPLACEMENT_17___}) format("truetype");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'Panton_BlackItalic';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_18___}) format("woff2"), url(${___CSS_LOADER_URL_REPLACEMENT_19___}) format("woff"), url(${___CSS_LOADER_URL_REPLACEMENT_20___}) format("truetype");
  font-weight: 900;
  font-style: italic; }

@font-face {
  font-family: 'Panton_Light';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_21___}) format("woff2"), url(${___CSS_LOADER_URL_REPLACEMENT_22___}) format("woff"), url(${___CSS_LOADER_URL_REPLACEMENT_23___}) format("truetype");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Panton_ExtraBold';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_24___}) format("woff2"), url(${___CSS_LOADER_URL_REPLACEMENT_25___}) format("woff"), url(${___CSS_LOADER_URL_REPLACEMENT_26___}) format("truetype");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Panton_Bold';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_27___}) format("woff2"), url(${___CSS_LOADER_URL_REPLACEMENT_28___}) format("woff"), url(${___CSS_LOADER_URL_REPLACEMENT_29___}) format("truetype");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Panton_Regular';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_30___}) format("woff2"), url(${___CSS_LOADER_URL_REPLACEMENT_31___}) format("woff"), url(${___CSS_LOADER_URL_REPLACEMENT_32___}) format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Panton_Thin';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_33___}) format("woff2"), url(${___CSS_LOADER_URL_REPLACEMENT_34___}) format("woff"), url(${___CSS_LOADER_URL_REPLACEMENT_35___}) format("truetype");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: 'Panton_HeavyItalic';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_36___}) format("woff2"), url(${___CSS_LOADER_URL_REPLACEMENT_37___}) format("woff"), url(${___CSS_LOADER_URL_REPLACEMENT_38___}) format("truetype");
  font-weight: 900;
  font-style: italic; }

@font-face {
  font-family: 'Panton_LightItalic';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_39___}) format("woff2"), url(${___CSS_LOADER_URL_REPLACEMENT_40___}) format("woff"), url(${___CSS_LOADER_URL_REPLACEMENT_41___}) format("truetype");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: 'Panton_ExtraLight';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_42___}) format("woff2"), url(${___CSS_LOADER_URL_REPLACEMENT_43___}) format("woff"), url(${___CSS_LOADER_URL_REPLACEMENT_44___}) format("truetype");
  font-weight: 200;
  font-style: normal; }

@font-face {
  font-family: 'Panton_ThinItalic';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_45___}) format("woff2"), url(${___CSS_LOADER_URL_REPLACEMENT_46___}) format("woff"), url(${___CSS_LOADER_URL_REPLACEMENT_47___}) format("truetype");
  font-weight: 100;
  font-style: italic; }

@font-face {
  font-family: 'Panton_RegularItalic';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_48___}) format("woff2"), url(${___CSS_LOADER_URL_REPLACEMENT_49___}) format("woff"), url(${___CSS_LOADER_URL_REPLACEMENT_50___}) format("truetype");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'Panton_Heavy';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_51___}) format("woff2"), url(${___CSS_LOADER_URL_REPLACEMENT_52___}) format("woff"), url(${___CSS_LOADER_URL_REPLACEMENT_53___}) format("truetype");
  font-weight: 900;
  font-style: normal; }

.pretty-number {
  position: relative;
  font-family: inherit !important;
  transition: color 0ms !important; }
  .pretty-number__tooltip {
    display: inline-flex;
    visibility: hidden;
    position: fixed;
    transform: translateX(-50%);
    z-index: 10;
    padding: 13px 12px;
    border-radius: 6px;
    opacity: 0;
    background: white;
    font-family: "Panton_SemiBold" !important;
    box-shadow: 0 4px 25px rgba(63, 54, 137, 0.25);
    font-size: 14px;
    line-height: 1;
    color: black;
    transition: 0.3s opacity;
    cursor: text; }
  .pretty-number__currency {
    font-family: "Panton_SemiBold" !important;
    color: #6B93C0;
    transition: 0.3s opacity;
    padding-left: 0.25em;
    text-transform: uppercase; }

body.dark .pretty-number__tooltip {
  background: #313354;
  color: #c3ceeb; }

.chart-tooltip,
#chartjs-tooltip {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  width: 150px;
  min-width: 150px;
  max-width: 150px;
  transform: translateY(-12%);
  background: #FFFFFF;
  border-radius: 6px;
  color: #000000;
  transition: all 0.1s ease;
  padding: 12px 15px;
  box-shadow: 0px 15px 50px rgba(80, 100, 124, 0.1), 0px 10px 15px rgba(80, 100, 124, 0.16);
  font-size: 12px;
  z-index: 2; }
  .chart-tooltip--balanceStuct,
  #chartjs-tooltip--balanceStuct {
    transform: translate(-50%, 0);
    color: #6A4BFF; }
  .chart-tooltip__wrapper,
  #chartjs-tooltip__wrapper {
    margin: 0; }
  .chart-tooltip__section,
  #chartjs-tooltip__section {
    margin: 15px 14px 17px 19px; }
  .chart-tooltip__triangle,
  #chartjs-tooltip__triangle {
    position: absolute;
    bottom: -4px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    border-radius: 2px;
    width: 10px;
    height: 10px;
    background: #FFFFFF; }
  .chart-tooltip__head,
  #chartjs-tooltip__head {
    color: #BDBDBD; }
    .chart-tooltip__head--rewards,
    #chartjs-tooltip__head--rewards {
      margin-bottom: 5px; }
  .chart-tooltip__title,
  #chartjs-tooltip__title {
    color: #BDBDBD;
    font-weight: 400;
    margin-bottom: 5px;
    font-size: 12px; }
  .chart-tooltip__date,
  #chartjs-tooltip__date {
    color: #59779a;
    font-weight: 400;
    margin-bottom: 5px;
    font-size: 10px; }
  .chart-tooltip__balance-native, .chart-tooltip__balance-usd, .chart-tooltip__balance-btc, .chart-tooltip__rate,
  #chartjs-tooltip__balance-native,
  #chartjs-tooltip__balance-usd,
  #chartjs-tooltip__balance-btc,
  #chartjs-tooltip__rate {
    font-weight: 700;
    color: #6A4BFF; }
  .chart-tooltip__balance-native, .chart-tooltip__balance-usd, .chart-tooltip__balance-btc,
  #chartjs-tooltip__balance-native,
  #chartjs-tooltip__balance-usd,
  #chartjs-tooltip__balance-btc {
    display: flex; }
  .chart-tooltip__balance-btc--rewards,
  #chartjs-tooltip__balance-btc--rewards {
    margin-bottom: 8px; }
  .chart-tooltip__balance-btc--balanceStruct,
  #chartjs-tooltip__balance-btc--balanceStruct {
    margin-bottom: 0; }
  .chart-tooltip__balance-usd, .chart-tooltip__balance-native,
  #chartjs-tooltip__balance-usd,
  #chartjs-tooltip__balance-native {
    margin-bottom: 2px; }
    .chart-tooltip__balance-usd--balanceStruct, .chart-tooltip__balance-native--balanceStruct,
    #chartjs-tooltip__balance-usd--balanceStruct,
    #chartjs-tooltip__balance-native--balanceStruct {
      margin-bottom: 5px; }
  .chart-tooltip__currency,
  #chartjs-tooltip__currency {
    color: #000000;
    font-weight: 300;
    margin-left: 3px; }
  .chart-tooltip__vertical-line,
  #chartjs-tooltip__vertical-line {
    left: 50%;
    position: absolute;
    background: #6A4BFF;
    width: 2px;
    transition: 30ms;
    z-index: 1;
    transition-delay: 30ms;
    transform: translateX(-50%); }
  .chart-tooltip__circle,
  #chartjs-tooltip__circle {
    position: absolute;
    bottom: -30px;
    height: 16px;
    width: 16px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    border-radius: 50%;
    border: 3px solid;
    background: #FFFFFF; }
  .chart-tooltip__horizontal-line,
  #chartjs-tooltip__horizontal-line {
    border-bottom: 1px solid #DADADA;
    width: 100%;
    margin-bottom: 8px; }

.chart-tooltip__head--rewards {
  display: flex;
  flex-direction: column; }
  .chart-tooltip__head--rewards span {
    color: #59779a;
    font-weight: 400;
    font-size: 10px; }

.chart-tooltip {
  transform: translate(-50%, -137%);
  width: -moz-fit-content;
  width: fit-content;
  min-width: 100px;
  max-width: 150px; }

.text-color--red {
  color: #FA3B33; }

* {
  box-sizing: border-box; }

*:not(path) {
  transition: all 0.3s; }

*::-webkit-scrollbar {
  width: 4px; }

*::-webkit-scrollbar-track {
  background: transparent; }

*::-webkit-scrollbar-thumb {
  background-color: #C3CEEB;
  border-radius: 8px; }

* {
  scrollbar-width: thin;
  scrollbar-color: #C3CEEB transparent; }

html {
  font-family: 'Panton_Regular'; }

textarea {
  font-family: "Panton_SemiBold"; }

body {
  margin: 0px;
  position: relative;
  overflow-y: overlay;
  overflow-x: hidden; }

button {
  border: none;
  cursor: pointer;
  outline: none; }

input {
  outline: none; }

textarea {
  font-family: 'Panton_SemiBold'; }

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease; }

.fade-enter-from,
.fade-leave-to,
.fade1-enter-from,
.fade1-leave-to {
  opacity: 0; }

.fade1-enter-active,
.fade1-leave-active {
  transition: opacity 0.3s ease; }

.slide-from-top-enter-active {
  transition: all 0.2s ease-out; }

.slide-from-top-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1); }

.slide-from-top-enter-from,
.slide-from-top-leave-to {
  transform: translateY(-20px);
  opacity: 0; }

.el-pager li {
  font-size: 16px !important;
  line-height: 19px !important;
  color: #AFBCCB !important;
  min-width: 22px !important;
  border-top: 2px solid transparent !important;
  display: inline-flex !important;
  justify-content: center !important;
  align-items: center !important; }
  @media (max-width: 1279.96px) {
    .el-pager li {
      font-size: 14px !important;
      line-height: 17px !important; } }

.el-pager li:hover {
  color: #6A4BFF !important; }

.el-pager li.active {
  color: #1A53F0 !important;
  border-top: 2px solid #1A53F0 !important; }

.el-pager .more::before {
  content: "__";
  padding-bottom: 16px; }

.el-switch__core .el-switch__action {
  top: -0.1px !important;
  left: -1px !important;
  width: 24px !important;
  height: 24px !important;
  background: linear-gradient(282.38deg, #FFFFFF 0.64%, #FCF8F8 75.95%);
  border: 0.5px solid #C3CEEB; }

.el-switch__core {
  width: 48px !important;
  height: 24px !important;
  border-radius: 14px !important;
  border: none !important; }

.el-switch.is-checked .el-switch__core .el-switch__action {
  margin-left: 25px !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.191761), 1px 4px 13px rgba(126, 126, 126, 0.124208) !important; }

.el-input__inner {
  font-size: 16px !important;
  line-height: 19px !important;
  color: #AFBCCB !important;
  border: 1px solid #C3CEEB !important; }
  .el-input__inner:focus {
    border-color: #1A53F0 !important; }

.date-picker .el-input__inner {
  border: none !important; }

.date-picker .el-range-editor.el-input__inner {
  display: flex;
  align-items: center;
  width: 100%;
  height: 18px;
  padding: 0;
  border-radius: 8px; }

.date-picker .el-date-editor .el-range-input {
  font-size: 14px;
  line-height: 17px;
  font-family: 'Panton_SemiBold';
  color: #000000;
  height: initial;
  width: 46%; }

.date-picker .el-date-editor .el-range-separator {
  letter-spacing: -1px;
  color: #AFBCCB;
  padding: 0;
  padding-bottom: 10px;
  height: initial;
  width: initial;
  line-height: initial; }

.date-picker .el-date-editor .el-range__close-icon {
  display: none; }

.date-picker .el-date-editor .el-range__icon {
  display: none;
  width: 0;
  height: 0;
  margin: 0;
  line-height: 0; }

.date-picker .el-range-editor.is-disabled input,
.date-picker .el-range-editor.is-disabled {
  background-color: #F0F3FD; }

.el-date-table th {
  font-size: 9px;
  line-height: 11px;
  text-align: center;
  color: #6B93C0 !important;
  font-family: 'Panton_SemiBold';
  border-bottom: 0.35823px solid #C3CEEB !important; }

.el-date-range-picker__header div {
  line-height: 19px;
  text-align: center;
  color: #0A2778;
  font-family: 'Panton_SemiBold'; }

.el-picker-panel__icon-btn {
  color: #1A53F0 !important;
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0 !important;
  border-radius: 50%; }
  .el-picker-panel__icon-btn:hover {
    background: #F0F3FD; }

.el-picker-panel {
  color: #000000 !important; }

.el-date-table td.start-date span {
  background-color: #1A53F0 !important; }

.el-date-table td.end-date span {
  background-color: #FFFFFF !important;
  color: #1A53F0 !important; }

.el-date-table td.in-range div,
.el-date-table td.in-range div:hover,
.el-date-table.is-week-mode .el-date-table__row.current div,
.el-date-table.is-week-mode .el-date-table__row:hover div {
  background-color: rgba(197, 220, 250, 0.5) !important; }

.el-slider * {
  transition: none; }

.el-slider__runway {
  background-color: #FFFFFF !important;
  height: 4px !important;
  border-radius: 8px !important;
  margin: 0px !important; }

.el-slider__bar {
  background-color: #6A4BFF !important;
  height: 4px !important;
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important; }

.el-slider__button {
  width: 24px !important;
  height: 24px !important;
  border: 3px solid #6A4BFF !important; }
  @media (max-width: 1279.96px) {
    .el-slider__button {
      width: 16px !important;
      height: 16px !important;
      border: 2px solid #6A4BFF !important; } }

::-moz-placeholder {
  font-family: 'Panton_SemiBold';
  font-size: 14px;
  line-height: 17px;
  color: #6B93C0;
  opacity: 1; }

::placeholder {
  font-family: 'Panton_SemiBold';
  font-size: 14px;
  line-height: 17px;
  color: #6B93C0;
  opacity: 1; }

:-ms-input-placeholder {
  font-family: 'Panton_SemiBold';
  font-size: 14px;
  line-height: 17px;
  color: #6B93C0; }

::-ms-input-placeholder {
  font-family: 'Panton_SemiBold';
  font-size: 14px;
  line-height: 17px;
  color: #6B93C0; }

.inactive {
  opacity: .5; }

.grecaptcha-badge {
  display: none !important; }

.description-text {
  font-family: 'Panton_Regular';
  font-weight: 400;
  font-size: 16px; }
  @media (max-width: 1024.98px) {
    .description-text {
      font-size: 14px; } }
  .description-text.color-mid-blue {
    color: #6B93C0; }

.title-text {
  font-size: 20px;
  color: #000000;
  font-family: 'Panton_Bold'; }
  @media (max-width: 1024.98px) {
    .title-text {
      font-size: 16px; } }

.constrict__icon,
.expand__icon {
  transition: 0.1s ease-in-out; }
  .constrict__icon circle,
  .expand__icon circle {
    fill: #DAE1F2; }
  .constrict__icon path,
  .expand__icon path {
    fill: #8b9bc7; }
  .constrict__icon:hover circle,
  .expand__icon:hover circle {
    fill: #0A2778; }
  .constrict__icon:hover path,
  .expand__icon:hover path {
    fill: #FFFFFF; }

.el-popper.is-rewards-list-tooltip {
  background: #FFFFFF;
  box-shadow: 0px 4px 25px rgba(63, 54, 137, 0.25);
  border-radius: 6px;
  color: #000000; }

.el-popper.is-rewards-list-tooltip .el-popper__arrow::before {
  background: #FFFFFF; }

.notAllowed {
  cursor: not-allowed !important; }

body.dark .expand__icon circle {
  fill: #313354; }

body.dark .el-switch__core {
  background: #393C5F !important; }

body.dark .el-pager li {
  background: none; }

body.dark .el-select__popper.el-popper[role=tooltip][data-popper-placement^=top] .el-popper__arrow::before {
  border-color: #8496c0;
  background: #8496c0; }

body.dark .el-input__inner {
  background: #1B1C31;
  border: 1px solid #26273B !important; }

body.dark .el-select__popper {
  border-radius: 0;
  border: 1px solid #8496c0 !important; }

body.dark .el-select-dropdown__list {
  background: #1B1C31; }

body.dark .el-select-dropdown__item.selected {
  background: #26273B; }

body.dark .el-popper.is-rewards-list-tooltip {
  background: #313354;
  box-shadow: 0px 4px 25px rgba(63, 54, 137, 0.25);
  border-radius: 6px;
  color: #000000; }

body.dark .el-popper.is-rewards-list-tooltip .el-popper__arrow::before {
  background: #313354; }

body.dark .el-popper.is-rewards-list-tooltip {
  color: #fff; }

body.dark .el-switch__core .el-switch__action {
  background: #3a5293;
  border-color: #3a5293; }

body.dark .el-switch.is-checked .el-switch__core .el-switch__action {
  background: #6b93c0;
  border-color: #6b93c0; }

body.dark .el-date-editor.el-date-editor--daterange {
  background: transparent; }

body.dark .el-date-editor .el-range-input {
  background: transparent; }

body.dark .date-picker {
  border: 1px solid #4b4c63; }

body.dark .el-picker-panel__body,
body.dark .el-picker-panel__sidebar {
  background: #26273B;
  border: 1px solid #4b4c63; }

body.dark .el-date-table td {
  color: #fff; }

body.dark .el-picker-panel [slot=sidebar], body.dark .el-picker-panel__sidebar {
  background-color: #26273B;
  border: 1px solid #4b4c63; }

body.dark .el-picker-panel__shortcut,
body.dark .el-date-range-picker__header div {
  color: #AFBCCB; }

body.dark .el-date-table td.disabled div {
  background-color: #4b4c63; }

body.dark .el-date-range-picker__content.is-left {
  border-color: #4b4c63; }

body.dark .el-picker__popper.el-popper[role=tooltip][data-popper-placement^=bottom] .el-popper__arrow::before {
  border-color: #4b4c63; }

body.dark .el-icon-d-arrow-left:before,
body.dark .el-icon-arrow-left:before {
  color: #AFBCCB; }

body.dark .date-picker .el-date-editor .el-range-input {
  color: #AFBCCB; }

body.dark .el-icon-d-arrow-right:before,
body.dark .el-icon-arrow-right:before {
  color: #AFBCCB; }

body.dark .constrict__icon circle,
body.dark .expand__icon circle {
  fill: #313354; }
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
