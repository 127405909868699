// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* BLACK */
body.dark .sidebar__logo[data-v-f8179bfa] {
  fill: #FFFFFF;
}
body.dark .compact.sidebar[data-v-f8179bfa] {
  background: #1c1d2e;
}
.modal_content[data-v-f8179bfa] {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 600px;
  background: #FFFFFF;
  border-radius: 16px;
  box-shadow: 15px 15px 15px rgba(63, 54, 137, 0.2);
}
.modal_content__content[data-v-f8179bfa] {
    display: flex;
    flex-direction: column;
    padding: 31px 40px 40px 40px;
    align-items: center;
    position: relative;
}
.modal_content__header[data-v-f8179bfa] {
    height: 16px;
    background: #6A4BFF;
    border-radius: 16px 16px 0px 0px;
}
.modal_content__close-icon[data-v-f8179bfa] {
    position: absolute;
    right: 39px;
    top: 30px;
    cursor: pointer;
    width: 16px;
    height: 16px;
}
.modal_content__close-icon[data-v-f8179bfa]:hover {
      fill: #6A4BFF;
}
.modal_content__image[data-v-f8179bfa] {
    margin-top: 42px;
    margin-bottom: 32px;
}
.modal_content__title[data-v-f8179bfa] {
    margin-bottom: 7px;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
}
.modal_content__desc[data-v-f8179bfa] {
    margin-bottom: 23px;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #6B93C0;
}
.modal__btn[data-v-f8179bfa] {
  text-align: center;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
