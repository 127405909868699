// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* BLACK */
body.dark .sidebar__logo[data-v-20396305] {
  fill: #FFFFFF;
}
body.dark .compact.sidebar[data-v-20396305] {
  background: #1c1d2e;
}
.noCat[data-v-20396305] {
  max-height: 85%;
}
@media (min-width: 1280px) and (max-width: 1919.98px) {
.noCat .cat-page__section[data-v-20396305] {
      padding-bottom: 19px;
}
.noCat .cat-page__cat-icon[data-v-20396305] {
      display: none;
}
.noCat .cat-page__title[data-v-20396305] {
      font-size: 20px;
      margin-bottom: 2px;
}
.noCat .cat-page__content[data-v-20396305] {
      padding: 26px 40px 40px 38px;
}
.noCat .cat-page__addresses[data-v-20396305] {
      max-height: 332px;
}
}
.cat-page[data-v-20396305] {
  width: 600px;
  display: flex;
  flex-direction: column;
  box-shadow: 15px 15px 15px rgba(63, 54, 137, 0.2);
  border-radius: 16px;
  background: #FFFFFF;
}
.cat-page__header[data-v-20396305] {
    min-height: 16px;
    background: #00A3FF;
    border-radius: 16px 16px 0 0;
}
.cat-page__content[data-v-20396305] {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 64px 40px 40px 38px;
    align-items: center;
    position: relative;
    overflow: hidden;
}
@media (min-width: 1280px) and (max-width: 1919.98px) {
.cat-page__content[data-v-20396305] {
        padding: 37px 40px 40px 38px;
}
}
@media (max-width: 1279.96px) {
.cat-page__content[data-v-20396305] {
        padding: 39px 44px 40px 34px;
}
}
.cat-page__close-icon[data-v-20396305] {
    position: absolute;
    right: 39px;
    top: 30px;
    cursor: pointer;
    width: 16px;
    height: 16px;
}
.cat-page__close-icon[data-v-20396305]:hover {
      fill: #6A4BFF;
}
.cat-page__cat-icon[data-v-20396305] {
    margin-right: 26px;
}
.cat-page__cat-icon svg[data-v-20396305] {
      width: 154px;
      height: 160px;
}
@media (min-width: 1280px) and (max-width: 1919.98px) {
.cat-page__cat-icon svg[data-v-20396305] {
        width: 92px;
        height: 96px;
}
}
@media (max-width: 1279.96px) {
.cat-page__cat-icon svg[data-v-20396305] {
        width: 80px;
        height: 80px;
}
}
.cat-page__section[data-v-20396305] {
    display: flex;
    border-bottom: 1px dashed #BCC2D8;
    padding-bottom: 44px;
    margin-bottom: 15px;
    align-items: center;
}
@media (min-width: 1280px) and (max-width: 1919.98px) {
.cat-page__section[data-v-20396305] {
        padding-bottom: 25px;
}
}
@media (max-width: 1279.96px) {
.cat-page__section[data-v-20396305] {
        padding-bottom: 19px;
}
}
.cat-page__info[data-v-20396305] {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.cat-page__title[data-v-20396305] {
    font-size: 30px;
    line-height: 30px;
    font-family: 'Panton_Bold';
}
@media (min-width: 1280px) and (max-width: 1919.98px) {
.cat-page__title[data-v-20396305] {
        margin-bottom: 10px;
}
}
@media (max-width: 1279.96px) {
.cat-page__title[data-v-20396305] {
        font-size: 20px;
}
}
.cat-page__message[data-v-20396305] {
    font-size: 14px;
    line-height: 22px;
    color: #6B93C0;
    margin: 0;
}
.cat-page__addresses[data-v-20396305] {
    width: calc(100% + 40px);
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 20px;
    margin-bottom: 20px;
    max-height: 292px;
}
@media (min-width: 1280px) and (max-width: 1919.98px) {
.cat-page__addresses[data-v-20396305] {
        max-height: 292px;
}
}
@media (max-width: 1279.96px) {
.cat-page__addresses[data-v-20396305] {
        max-height: 261px;
}
}
.cat-page__address[data-v-20396305] {
    display: flex;
    width: 100%;
}
.cat-page__add-address[data-v-20396305] {
    font-family: 'Panton_Light';
    font-size: 14px;
    line-height: 30px;
    color: #9E9E9E;
    margin-right: 20px;
}
.cat-page__new-address[data-v-20396305] {
    flex-grow: 1;
    margin-bottom: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.cat-page__icon[data-v-20396305] {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #6A4BFF;
    border-radius: 5px;
    margin-right: 18px;
}
.cat-page__icon svg[data-v-20396305] {
      fill: #FFFFFF;
      max-width: 18px;
      max-height: 20px;
}
.cat-page__address-line[data-v-20396305] {
    font-size: 14px;
    line-height: 25px;
    font-family: 'Panton_Light';
}
.cat-page__input[data-v-20396305] {
    width: 100%;
    height: 68px;
    margin-bottom: 32px;
}
@media (min-width: 1280px) and (max-width: 1919.98px) {
.cat-page__input[data-v-20396305] {
        margin-bottom: 16px;
}
}
@media (max-width: 1279.96px) {
.cat-page__input[data-v-20396305] {
        margin-bottom: 16px;
}
}
body.dark .cat-page[data-v-20396305] {
  box-shadow: none;
}
body.dark .cat-page__title[data-v-20396305] {
    color: #FFFFFF;
}
body.dark .cat-page__address-line[data-v-20396305] {
    color: #FFFFFF;
}
body.dark .cat-page__content[data-v-20396305] {
    background: #26273B;
}
body.dark .cat-page__content svg[data-v-20396305] {
      fill: #FFFFFF;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
