// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* BLACK */
body.dark .sidebar__logo {
  fill: #FFFFFF;
}
body.dark .compact.sidebar {
  background: #1c1d2e;
}
body.dark .json-container {
  background-color: #26273b;
  color: #fff;
}
body.dark .json-container .json-key {
    color: #6b93c0;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
