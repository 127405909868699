// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* BLACK */
body.dark .sidebar__logo[data-v-59c14148] {
  fill: #FFFFFF;
}
body.dark .compact.sidebar[data-v-59c14148] {
  background: #1c1d2e;
}
.toasts[data-v-59c14148] {
  position: fixed;
  left: 50%;
  top: 0;
  z-index: 99999;
  padding: 10px;
  transform: translateX(-50%);
  width: 100%;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
