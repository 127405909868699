// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* BLACK */
body.dark .sidebar__logo[data-v-c0283204] {
  fill: #FFFFFF;
}
body.dark .compact.sidebar[data-v-c0283204] {
  background: #1c1d2e;
}
.mobile-app-modal[data-v-c0283204] {
  width: 320px;
  background: #FFFFFF;
  box-shadow: 15px 15px 15px rgba(63, 54, 137, 0.2);
  border-radius: 16px;
  margin: auto;
}
.mobile-app-modal__header[data-v-c0283204] {
    height: 16px;
    background: #6A4BFF;
    border-radius: 16px 16px 0px 0px;
}
.mobile-app-modal__main[data-v-c0283204] {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 39px 24px 38px 24px;
}
.mobile-app-modal__close-icon[data-v-c0283204] {
    position: absolute;
    top: 31px;
    right: 32px;
    width: 15px;
    height: 24px;
    cursor: pointer;
}
.mobile-app-modal__close-icon[data-v-c0283204]:hover {
      fill: #6A4BFF;
}
.mobile-app-modal__icon[data-v-c0283204] {
    margin-bottom: 5px;
}
.mobile-app-modal__title[data-v-c0283204] {
    font-size: 18px;
    line-height: 30px;
    font-family: 'Panton_Bold';
}
.mobile-app-modal__info[data-v-c0283204] {
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #6B93C0;
    margin-bottom: 13px;
}
.mobile-app-modal__iost-button[data-v-c0283204] {
    margin-bottom: 16px;
}
body.dark .mobile-app-modal[data-v-c0283204] {
  box-shadow: none;
}
body.dark .mobile-app-modal__main[data-v-c0283204] {
    background: #26273B;
}
body.dark .mobile-app-modal__title[data-v-c0283204] {
    color: #FFFFFF;
}
body.dark .mobile-app-modal__close-icon[data-v-c0283204] {
    fill: #FFFFFF;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
