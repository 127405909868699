// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* BLACK */
body.dark .sidebar__logo[data-v-38e699ee] {
  fill: #FFFFFF;
}
body.dark .compact.sidebar[data-v-38e699ee] {
  background: #1c1d2e;
}
.primary-button[data-v-38e699ee] {
  width: 200px;
  min-height: 60px;
  border-radius: 50px;
  box-shadow: var(--buttonShadow);
  font-family: 'Panton_SemiBold';
  font-size: 20px;
  line-height: 24px;
  background: var(--backgroundColor);
  color: var(--textColor);
  border: var(--buttonBorder);
  z-index: 1;
}
@media (min-width: 1280px) and (max-width: 1919.98px) {
.primary-button[data-v-38e699ee] {
      width: 160px;
      min-height: 56px;
      font-size: 14px;
      line-height: 17px;
}
}
@media (max-width: 1279.96px) {
.primary-button[data-v-38e699ee] {
      width: 120px;
      min-height: 40px;
      font-size: 14px;
      line-height: 17px;
}
}
.primary-button[data-v-38e699ee]:hover {
    background: var(--hoverededBgColor);
    color: var(--hoveredColor);
    border: var(--buttonHoverBorder);
}
.primary-button[data-v-38e699ee]:disabled,
  .primary-button [disabled][data-v-38e699ee] {
    background: #C3CEEB;
    cursor: pointer;
    box-shadow: none;
    pointer-events: none;
}
.primary-button.loading[data-v-38e699ee] {
    background: #c3ceeb;
    display: flex;
    align-items: center;
    justify-content: center;
}
.primary-button.loading svg[data-v-38e699ee] {
      margin-right: 7px;
}
.no-decoration[data-v-38e699ee] {
  font-family: 'Panton_Bold';
  font-size: 18px;
  line-height: 27px;
  color: #0A2778;
  width: auto;
  min-height: auto;
  box-shadow: none;
  text-decoration: underline;
  background: none;
}
@media (max-width: 1279.96px) {
.no-decoration[data-v-38e699ee] {
      width: auto;
      min-height: auto;
}
}
.no-decoration[data-v-38e699ee]:hover {
    background: none;
    color: #1A53F0;
    box-shadow: none;
}
.no-decoration[data-v-38e699ee]:disabled,
  .no-decoration [disabled][data-v-38e699ee] {
    background: none;
    cursor: pointer;
    box-shadow: none;
    color: #AFBCCB;
}
@keyframes rotating-38e699ee {
from {
    transform: rotate(0deg);
}
to {
    transform: rotate(360deg);
}
}
.rotating[data-v-38e699ee] {
  animation: rotating-38e699ee 2s linear infinite;
}
body.dark .primary-button[data-v-38e699ee] {
  box-shadow: none;
}
body.dark .primary-button[data-v-38e699ee]:disabled,
  body.dark .primary-button [disabled][data-v-38e699ee] {
    background: #393b53;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
