// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* BLACK */
body.dark .sidebar__logo[data-v-0321d2b2] {
  fill: #FFFFFF;
}
body.dark .compact.sidebar[data-v-0321d2b2] {
  background: #1c1d2e;
}
.toast[data-v-0321d2b2] {
  position: absolute;
  display: flex;
  width: 500px;
  border-radius: 10px;
  margin-bottom: 10px;
  background-color: #FFFFFF;
  box-shadow: 0 15px 50px rgba(80, 100, 124, 0.1), 0 10px 15px rgba(80, 100, 124, 0.16);
  overflow: hidden;
  right: 15px;
  top: 15px;
}
.toast .hash-wrap[data-v-0321d2b2] {
    color: #00A3FF;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.toast .hash-wrap a[data-v-0321d2b2] {
      text-decoration: none;
      color: #00A3FF;
}
.toast .hash-wrap svg[data-v-0321d2b2] {
      margin-left: 5px;
}
.toast .hash-wrap .hash-link[data-v-0321d2b2] {
      width: 17px;
      height: 15px;
}
.toast[data-v-0321d2b2]:last-child {
    margin-bottom: 0;
}
.toast--info .toast__icon[data-v-0321d2b2] {
    background-color: #00A3FF;
}
.toast--info .toast__title[data-v-0321d2b2] {
    color: #00A3FF;
}
.toast--info .toast__title.hash[data-v-0321d2b2] {
      color: #000000;
}
.toast--info .toast__close svg[data-v-0321d2b2] {
    fill: #00A3FF;
}
.toast--success .toast__icon[data-v-0321d2b2] {
    background-color: #0FB774;
}
.toast--success .toast__title[data-v-0321d2b2] {
    color: #0FB774;
}
.toast--success .toast__title.hash[data-v-0321d2b2] {
      color: #000000;
}
.toast--success .toast__close svg[data-v-0321d2b2] {
    fill: #0FB774;
}
.toast--warning .toast__icon[data-v-0321d2b2] {
    background-color: #FA3B33;
}
.toast--warning .toast__title[data-v-0321d2b2] {
    color: #FA3B33;
}
.toast--warning .toast__title.hash[data-v-0321d2b2] {
      color: #000000;
}
.toast--warning .toast__close svg[data-v-0321d2b2] {
    fill: #FA3B33;
}
.toast__icon[data-v-0321d2b2] {
    width: 100px;
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.toast__icon svg[data-v-0321d2b2] {
      width: 50px;
      height: 50px;
}
.toast__content[data-v-0321d2b2] {
    padding: 13px 48px 17px 16px;
}
.toast__title[data-v-0321d2b2] {
    font-family: 'Panton_Bold';
    font-size: 20px;
    line-height: 30px;
}
.toast__text[data-v-0321d2b2] {
    font-size: 14px;
    line-height: 20px;
}
.toast__close[data-v-0321d2b2] {
    position: absolute;
    padding: 5px;
    top: 15px;
    right: 15px;
    cursor: pointer;
}
.toast__close:hover svg[data-v-0321d2b2] {
      fill: #000000;
}
.toast__close svg[data-v-0321d2b2] {
      width: 16px;
      height: 18px;
}
body.dark .toast[data-v-0321d2b2] {
  background: #101120;
}
body.dark .toast__content[data-v-0321d2b2] {
    color: #FFFFFF;
}
body.dark .toast--info .toast__icon[data-v-0321d2b2] {
    background-color: #00A3FF;
}
body.dark .toast--info .toast__title[data-v-0321d2b2] {
    color: #00A3FF;
}
body.dark .toast--info .toast__title.hash[data-v-0321d2b2] {
      color: none;
}
body.dark .toast--info .toast__close svg[data-v-0321d2b2] {
    fill: #00A3FF;
}
body.dark .toast--success .toast__icon[data-v-0321d2b2] {
    background-color: #0FB774;
}
body.dark .toast--success .toast__title[data-v-0321d2b2] {
    color: #0FB774;
}
body.dark .toast--success .toast__title.hash[data-v-0321d2b2] {
      color: none;
}
body.dark .toast--success .toast__close svg[data-v-0321d2b2] {
    fill: #0FB774;
}
body.dark .toast--warning .toast__icon[data-v-0321d2b2] {
    background-color: #FA3B33;
}
body.dark .toast--warning .toast__title[data-v-0321d2b2] {
    color: #FA3B33;
}
body.dark .toast--warning .toast__title.hash[data-v-0321d2b2] {
      color: none;
}
body.dark .toast--warning .toast__close svg[data-v-0321d2b2] {
    fill: #FA3B33;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
